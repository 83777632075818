.Login {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;

  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  /* justify-content: center; */
}

.Login__brand {
}

.Login__brand img {
  height: 64px;
}

.Login__brand {
}

.Login__form {
  width: 320px;
  border: solid 1px whitesmoke;
  padding: 16px;
  margin-top: 24px;
}

.Login__form__group {
  margin-bottom: 16px;
}

.Login__form__group__label {
  margin-bottom: 8px;
  font-size: 12px;
}

.Login__form__group__input {
  width: 100%;
  height: 32px;
  border: solid 1px grey;
  outline: none;
  border-radius: 4px;
  padding-left: 8px;
}

.Login__form__submit {
  height: 40px;
  width: 100%;
  background: black;
  color: white;
  border-radius: 8px;
  border: none;
  margin-top: 16px;
  cursor: pointer;
}

.Login__form__submit.disabled {
  pointer-events: none;
  opacity: 0.3;
}
