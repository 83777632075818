.Application {
  background: white;
  height: 90%;
  width: 60%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  /* overflow-y: auto; */
}

.Application__header {
  padding-left: 24px;
  padding-right: 24px;
  /* padding: 24px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 64px;
  border-bottom: solid 1px #dae2e7;
  background: white;
}

.Application__header__title {
  flex-grow: 1;
}

.Application__header__title__primary {
  font-size: 16px;
  font-weight: 700;
}

.Application__header__title__secondary {
}

.Application__header__title__dismiss {
  cursor: pointer;
}

.Application__body {
  padding-top: 64px;
  height: 100%;
  overflow-y: auto;
}

.Application__section {
  padding: 24px;
}

.Application__section__title {
  font-size: 14px;
  font-weight: 700;
}

.Application__section__group {
  padding-top: 16px;
}

.Application__section__group__label {
  font-weight: 700;
  color: #818f97;
  font-size: 10px;
  margin-bottom: 4px;
}

.Application__section__group__value {
}
