.Nav {
  /* background: black; */
  /* color: white; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-right: solid 1px #dae2e7;
}

.Nav__logo {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  /* padding: 24px; */
}

.Nav__logo img {
  height: 24px;
}

.Nav__items {
  /* padding: 12px; */
  padding-left: 12px;
  padding-right: 12px;
}

.Nav__items__item {
  /* padding: 12px; */
  /* padding-left: 12px; */

  /* margin-top: 12px; */
  display: block;
  width: 100%;
  text-decoration: none;
  height: 32px;
}

.Nav__items__item__label {
  padding-left: 12px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  /* color: white; */
}

.Nav__items__item__label.selected {
  background: #dae2e7;
  font-weight: 700;
}
