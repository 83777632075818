.Layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 280px 1fr;
  position: relative;
  background: #f6f8f9;
}

.Modal-form-wrapper {
  max-height: 80%;
  height: auto;
  width: 360px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.Modal-form-wrapper__header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 0px;
  flex-shrink: 0;
  border-bottom: solid 1px #dae2e7;
}

.Modal-form-wrapper__header__title {
  font-weight: 700;
}

.Modal-form-wrapper__header__dismiss {
  margin-left: auto;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-form-wrapper__success-msg {
  background: #f5f5f5;
  margin: 16px;
  border-radius: 8px;
  padding: 16px;
}

.Modal-form-wrapper__form {
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
}

.Modal-form-wrapper__form__group {
  margin-bottom: 16px;
}

.Modal-form-wrapper__form__group__label {
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: 700;
  color: #818f97;
}

.Modal-form-wrapper__form__group__input {
  width: 100%;
  height: 32px;
  border: solid 1px #dae2e7;
  outline: none;
  border-radius: 4px;
  padding-left: 8px;
}

.Modal-form-wrapper__form__error {
  font-weight: 700;
  background: #fef6f6;
  color: #ec4c4c;
  padding: 12px;
  font-size: 10px;
}

.Modal-form-wrapper__form__submit {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  background: #37ce18;
  color: white;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  margin-top: 16px;
  cursor: pointer;
}

.Modal-form-wrapper__form__submit.disabled {
  pointer-events: none;
  opacity: 0.3;
}
