* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  --futura: "futura-pt", sans-serif;
  --futura-bold: "futura-pt-bold", sans-serif;
  --futura-condensed: "futura-pt-condensed", sans-serif;
  color: #2e3438;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  color: #2e3438;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 64px;
  background: white;
  display: flex;
  flex-direction: column;
}

.Page__header {
  padding-left: 24px;
  padding-right: 24px;
  /* padding: 24px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 64px;
  /* font-weight: 700; */
  /* font-size: 24px; */
}

.Page__header__title {
  font-size: 16px;
  font-weight: 700;
}

.Page__header__options {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.Page__header__options__search {
  height: 28px;
}

.Page__header__options__search__input {
  height: 28px;
  border: solid 1px #dae2e7;
  width: 240px;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 12px;
}

.Page__header__options__button {
  background: #37ce18;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.1px;
  border: none;
  cursor: pointer;
}

.Page__header__options__select {
  height: 28px;
  border: solid 1px #dae2e7;
  padding-left: 16px;
  margin-left: 16px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

.Page__header__options__select__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.Page__header__options__select__menu {
  position: absolute;
  /* height: 100px; */
  width: 200px;
  background: white;
  top: 32px;
  right: 0;
  border-radius: 8px;
  border: solid 1px #dae2e7;
}

.Page__header__options__select__menu__item {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  padding-left: 12px;
}

.Page__header__options__select__menu__item:hover {
  background: #f6f8f9;
}

.Page__header__options__button:hover {
}

.Page__body {
  flex-grow: 1;
  overflow: hidden;
}

.Page__table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Page__table__header {
}

.Page__table__body {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.Page__table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
}

.Page__table__row__data {
  border-right: solid 1px #dae2e7;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #dae2e7;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 24px; */
}

.Page__table__row__data__img {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  background: #f6f8f9;
}

.Page__table__row__data__img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.Page__table__row__data.options {
  justify-content: flex-end;
}

.Page__table__row__data__option {
  font-weight: 700;
  color: #818f97;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.Page__table__row__data__option.edit {
  color: #ffaa2b;
}

.Page__table__row__data__option.delete {
  color: #ec4c4c;
}

.Page__table__row__data:last-child {
  border-right: none;
}

.Page__table__header .Page__table__row {
  background: #f6f8f9;
  border-top: solid 1px #dae2e7;
  border-bottom: solid 1px #dae2e7;
  color: #818f97;
  font-size: 10px;
  font-weight: 700;
}

.Page__table__header .Page__table__row__data {
  border-bottom: none;
}
